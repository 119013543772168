import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import { createGmapApiLoader } from 'vue3-google-map';

const app = createApp(App);
app.use(router);

// Configuração da API da Google
/*const mapApiLoader = createGmapApiLoader({
    apiKey: 'AIzaSyDrU3lH_OXI0ipVxhtMiD3cFrEVoxCrnqc',
    version: '3.42',
    libraries: ['places'],
});
  
// Adiciona a API no app
app.use(mapApiLoader);*/

app.mount('#app');
