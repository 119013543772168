
import { defineComponent } from 'vue';
//import axios from 'axios';
import MyButton from '@/components/MyButton.vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import MyFooter from '@/components/MyFooter.vue';
//import { GMap, GMarker, GInfoWindow } from 'vue3-google-map';




export default defineComponent({
  name: 'HomeView',
  components: {
    MyButton,
    Carousel,
    Slide,
    Navigation,
    MyFooter,
    //GMap,
    //GMarker,1611
    //GInfoWindow,
  },
  data() {
    return {
        center: { lat: 37.7749, lng: -122.4194 },
        zoom: 12,
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        images: [
            require('@/assets/corteslide.jpg'),
            require('@/assets/corteslide2.jpg'),
            require('@/assets/corteslide3.jpg'),
            require('@/assets/corteslide4.jpg'),
            require('@/assets/corteslide5.jpg'),
            require('@/assets/corteslide6.jpg'),
            require('@/assets/corte-criança1.jpg'),
            require('@/assets/corte-criança2.jpg'),
            require('@/assets/corte-criança3.jpg'),
            require('@/assets/corte4.jpg'),
            require('@/assets/corte5.jpg'),
            require('@/assets/corteslide7.jpeg'),
            require('@/assets/corteslide8.jpeg'),
            require('@/assets/corteslide9.jpeg'),
            require('@/assets/corteslide10.jpeg')
        ],

    
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
        // 700px and up
            700: {
                itemsToShow: 3.5,
                snapAlign: 'center',
            },
            // 1024 and up
            1024: {
                itemsToShow: 4,
                snapAlign: 'start',
            },
        },
    };
  },
  methods: {
    
  },
});
