<template>
  <nav class="navbar navbar-expand-lg navbar-dark py-3">
    <div class="container">
      <router-link class="nav-link" to="/">Inicio</router-link>
      <a class="nav-link" href="#services">Serviços</a>
      <a class="nav-link" href="#about">Sobre</a>
      <a class="nav-link" href="#chamada">Contato</a>
    </div>
  </nav>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #ffffff;
}

nav a.router-link-exact-active {
  color: #f0da7b;
}
  .navbar {
    background-color: #272727;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .nav-link {
    color: #f3eded;
    text-decoration: none;
    margin-right: 1rem;

    &:hover {
      color: #007bff;
    }
  }

</style>
